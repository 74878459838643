<template>
  <main></main>
</template>

<script>
export default {
  name: 'EditProfile',
}
</script>

<style lang="less" scoped>

</style>
